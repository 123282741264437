import { ZTNA_APP_KEY } from '@pats/ztna-react-app'
import { ztnaPrismaAccessRoutes } from '@pats/ztna-react-app/manifest'
import type { AuthState } from '@sparky/framework'
import { definition as icon } from './faSlidersHSquare'
import { isZtnaAvail } from './feature'
import { i18n } from './i18n'

export default function manifest() {
	return async ({ auth }: { auth: AuthState }) => {
		// if (!auth.instances?.isFawkesRunning) return undefined

		const intl = i18n(navigator.language ?? 'en')
		// const avail = auth.instances.get('prisma_access_edition_onprem') ? () => true : isZtnaAvail
		const avail = true

		return {
			key: 'workflows',
			title: intl.formatMessage({ defaultMessage: 'Workflows' }),
			path: '/workflows',
			expandable: true,
			noRBACheck: true,
			exact: false,
			icon,
			mergeNav: true,
			children: [
				{
					key: ZTNA_APP_KEY,
					path: ztnaPrismaAccessRoutes.baseUrl,
					title: intl.formatMessage({ defaultMessage: 'ZTNA Connector' }),
					contentClassName: 'panwds-tw3',
					expandable: true,
					avail,
					children: [
						{
							key: 'overview',
							title: intl.formatMessage({ defaultMessage: 'Overview' }),
							path: ztnaPrismaAccessRoutes.overview,
						},
						{
							key: 'connector-groups',
							title: intl.formatMessage({ defaultMessage: 'Connector Groups' }),
							path: ztnaPrismaAccessRoutes.connectorGroups,
						},
						{
							key: 'connectors',
							title: intl.formatMessage({ defaultMessage: 'Connectors' }),
							path: ztnaPrismaAccessRoutes.connectors,
						},
						{
							key: 'app-targets',
							title: intl.formatMessage({ defaultMessage: 'Application Targets' }),
							path: ztnaPrismaAccessRoutes.applications,
						},
					],
					component: async () => import('../main').then((m) => m.activate()),
				},
			],
		}
	}
}
